<template>
  <div>
    <vx-card class="mb-1">
       <vs-row vs-type="flex" vs-justify="space-between" >
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">         
              <label class="vs-input--label mr-2">Marca</label>
              <vs-input class="mb-4 md:mb-0 w-full" v-model="filtro.marca" @keyup.enter="fetchLista(filtro)" />
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-button class="mr-2" color="primary" type="filled" icon-pack="feather" icon="icon-search" @click="fetchLista(filtro)"></vs-button>
          <vs-button class="mr-2" color="dark" type="border" icon-pack="feather" icon="icon-file" @click="limparFiltros"></vs-button>  
          <vs-button class="mr-2" color="warning" type="filled" icon-pack="feather" icon="icon-plus" @click="routeNovoRegistro"></vs-button>          
          <vs-button color="rgb(11, 189, 135)" type="filled" icon-pack="feather" icon="icon-save" @click="exportarParaCSV"></vs-button>          
        </vs-col>
      </vs-row>
    </vx-card>
     
    <h6 class="mt-2 text-danger"> {{dadosDaGrid.length }} registros </h6>

    <ag-grid-vue
      ref="agGridTable"           
      :gridOptions="configuracaoGrid.gridOptions"
      class="ag-theme-material ag-grid-table ag-grid-altura mt-2"
      :columnDefs="configuracaoGrid.columnDefs"
      :defaultColDef="configuracaoGrid.defaultColDef"
      :rowData="dadosDaGrid"
      :rowHeight="20"
      :headerHeight="25"        
      rowSelection="single"
      colResizeDefault="shift"
      @row-selected="onRowSelectedRegistro"    
      :animateRows="true"
      :floatingFilter="false"
      :pagination="false"
      :paginationAutoPageSize="false"
      :suppressPaginationPanel="true"
      >
    </ag-grid-vue>      
    
  </div>

</template>

<script>
import axios from "@/axios.js"
import { AgGridVue } from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { CONSTANTE_STATUS_CLIENTE } from '@/constantesComboBox.js'

const URL_SERVLET = "/rest/MarcaVeiculo/Listar";
const NAME_ROUTE_EDIT = "cadastro-marca-veiculo-editar";

export default {
  components: {
    AgGridVue
  },
  data() {
    return {
      filtro : {marca: null},
      configuracaoGrid: { 
          gridApi: null, gridOptions: {}, 
          defaultColDef: { sortable: true, resizable: true, suppressMenu: true  },
          columnDefs: [        
            { headerName: 'Marca', field: 'marca', width: 130 },                             
          ]
      },
      dadosDaGrid: [],
      totalRegistros: 0      
    }
  },  
  created() {
      this.CONSTANTE_STATUS_CLIENTE = CONSTANTE_STATUS_CLIENTE;
  },
  methods: {
    limparFiltros() {
      this.filtro = {nome:  null};      
    },
    fetchLista(pFiltro) {     
      this.$vs.loading() 
      const params = new URLSearchParams();
      
      if (pFiltro.marca) {
        params.append('marca', pFiltro.marca);
      }

      axios.post(URL_SERVLET, params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
              this.totalRegistros = response.data.Resultado.quantidade;
              if (response.data.Resultado.quantidade > 1) {
                  this.dadosDaGrid = response.data.Resultado.lista.item;
              } else if (response.data.Resultado.quantidade === 1) {
                  this.dadosDaGrid = [];
                  this.dadosDaGrid.push(response.data.Resultado.lista.item);
              } else {
                  this.dadosDaGrid = [];
              }                   
          }
      })
      .catch((error) => { 
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
      })    
                        
    },
    routeNovoRegistro() {
      let self_ = this;

      self_.$router.push({
            name: NAME_ROUTE_EDIT                     
      })
    },
    onRowSelectedRegistro(evento) {
      let self_ = this;
      let registro = evento.node.data;
      
      self_.$router.push({
            name: NAME_ROUTE_EDIT,
            params: {
              registroOriginal: registro
            }
      })

    },
    exportarParaCSV() {
      var params = {columnSeparator: ';', fileName: 'MarcaVeiculo.csv'};
      this.configuracaoGrid.gridApi.exportDataAsCsv(params);
    }
    
  },
  mounted() {
    this.configuracaoGrid.gridApi = this.configuracaoGrid.gridOptions.api;
    this.configuracaoGrid.gridApi.sizeColumnsToFit();      
    this.fetchLista(this.filtro);
  }
}

</script>
<style lang="scss" scoped>
  .ag-grid-altura{
    height: calc(100vh - 250px);
  }
</style>